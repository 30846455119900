import React from "react";
import "../css/team.css";
import emon from "../images/emon.jpg";
import farhan from "../images/farhan.jpg";
import roky from "../images/roky.jpg";
import tacin from "../images/tacin.jpg";
import refat from "../images/refat.jpg";
import arman from "../images/arman.jpg";
import jitu from "../images/jitu.jpg";


function Team() {
    return (
        <>
            <div className="container p-5">
                <div className="leaderShip">
                    <h1 className="teamTitle">Our Leadership</h1>
                    <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3 justify-content-center">
                        <div className="col">
                            <div className="card h-100 shadow-sm commonCard">
                                <div className="text-center">


                                    <div className="img-hover-zoom img-hover-zoom--colorize">
                                        <img className="shadow" src={farhan}
                                            alt="Another zoom-on-hover effect" />
                                    </div>

                                </div>

                                <div className="card-body">
                                    <div className="clearfix mb-3">
                                    </div>
                                    <div className="my-2 text-center">
                                        <h1>Farhan Sadik</h1>
                                    </div>
                                    <div className="mb-3">

                                        <h2 className="text-uppercase text-center role">Founder & CEO</h2>

                                    </div>
                                    <div className="box">
                                        <div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><a href="https://github.com/Farhan-S"><i className="fab fa-github"></i></a></li>
                                                <li className="list-inline-item"><a href="https://linkedin.com/in/fsanik"><i className="fab fa-linkedin-in"></i></a></li>
                                                <li className="list-inline-item"><a href="https://fb.com/hello.farhan.sadik"><i className="fab fa-facebook"></i></a></li>
                                                <li className="list-inline-item"><a href=""><i className="fab fa-twitter"></i></a></li>
                                            </ul>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 shadow-sm commonCard">
                                <div className="text-center">


                                    <div className="img-hover-zoom img-hover-zoom--colorize">
                                        <img className="shadow" src={emon}
                                            alt="Another zoom-on-hover effect" />
                                    </div>

                                </div>

                                <div className="card-body">
                                    <div className="clearfix mb-3">




                                    </div>


                                    <div className="my-2 text-center">

                                        <h1>Md Nuruzzaman Emon</h1>

                                    </div>
                                    <div className="mb-3">

                                        <h2 className="text-uppercase text-center role">Founder & CTO</h2>

                                    </div>
                                    <div className="box">
                                        <div>
                                        <ul className="list-inline">
                                                <li className="list-inline-item"><a href="https://github.com/mdnuruzzamanemon"><i className="fab fa-github"></i></a></li>
                                                <li className="list-inline-item"><a href="https://www.linkedin.com/in/mdnuruzzamanemon/"><i className="fab fa-linkedin-in"></i></a></li>
                                                <li className="list-inline-item"><a href="https://www.facebook.com/mdnuruzzamanemon"><i className="fab fa-facebook"></i></a></li>
                                                <li className="list-inline-item"><a href="https://x.com/mNuruzzamanEmon"><i className="fab fa-twitter"></i></a></li>
                                            </ul>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="ourTeam">
                    <h1 className="teamTitle">Our Team</h1>
                    <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3 justify-content-center">

                        <div className="col">
                            <div className="card h-100 shadow-sm commonCard">
                                <div className="text-center">


                                    <div className="img-hover-zoom img-hover-zoom--colorize">
                                        <img className="shadow" src={roky}
                                            alt="Another zoom-on-hover effect" />
                                    </div>

                                </div>

                                <div className="card-body">
                                    <div className="clearfix mb-3">



                                    </div>


                                    <div className="my-2 text-center">

                                        <h1>Hasanuzzaman Roky</h1>

                                    </div>
                                    <div className="mb-3">

                                        <h2 className="text-uppercase text-center role">Web Developer</h2>

                                    </div>
                                    <div className="box">
                                        <div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><i className="fab fa-github"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-linkedin-in"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-instagram"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-twitter"></i></li>
                                            </ul>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 shadow-sm commonCard">
                                <div className="text-center">


                                    <div className="img-hover-zoom img-hover-zoom--colorize">
                                        <img className="shadow" src={refat}
                                            alt="Another zoom-on-hover effect" />
                                    </div>

                                </div>

                                <div className="card-body">
                                    <div className="clearfix mb-3">


                                    </div>


                                    <div className="my-2 text-center">

                                        <h1>Mahmudul Hasan Refatt</h1>

                                    </div>
                                    <div className="mb-3">

                                        <h2 className="text-uppercase text-center role">Graphics Designer</h2>

                                    </div>
                                    <div className="box">
                                        <div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><i className="fab fa-github"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-linkedin-in"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-instagram"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-twitter"></i></li>
                                            </ul>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="col">
                            <div className="card h-100 shadow-sm commonCard">
                                <div className="text-center">

                                    <div className="img-hover-zoom img-hover-zoom--colorize">
                                        <img className="shadow" src={tacin}
                                            alt="Another zoom-on-hover effect" />
                                    </div>

                                </div>

                                <div className="card-body">
                                    <div className="clearfix mb-3">


                                    </div>


                                    <div className="my-2 text-center">

                                        <h1>Abdullah Al Tacin</h1>

                                    </div>
                                    <div className="mb-3">

                                        <h2 className="text-uppercase text-center role">Web Developer</h2>

                                    </div>
                                    <div className="box">
                                        <div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><i className="fab fa-github"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-linkedin-in"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-instagram"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-twitter"></i></li>
                                            </ul>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="col">
                            <div className="card h-100 shadow-sm commonCard">
                                <div className="text-center">


                                    <div className="img-hover-zoom img-hover-zoom--colorize">
                                        <img className="shadow" src={arman}
                                            alt="Another zoom-on-hover effect" />
                                    </div>

                                </div>

                                <div className="card-body">
                                    <div className="clearfix mb-3">


                                    </div>


                                    <div className="my-2 text-center">

                                        <h1>Arman Hossain</h1>

                                    </div>
                                    <div className="mb-3">

                                        <h2 className="text-uppercase text-center role">Android App Developer</h2>

                                    </div>
                                    <div className="box">
                                        <div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><i className="fab fa-github"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-linkedin-in"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-instagram"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-twitter"></i></li>
                                            </ul>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 shadow-sm commonCard">
                                <div className="text-center">


                                    <div className="img-hover-zoom img-hover-zoom--colorize">
                                        <img className="shadow" src={jitu}
                                            alt="Another zoom-on-hover effect" />
                                    </div>

                                </div>

                                <div className="card-body">
                                    <div className="clearfix mb-3">


                                    </div>


                                    <div className="my-2 text-center">

                                        <h1>Muhammad Jahid Hasan Jitu </h1>

                                    </div>
                                    <div className="mb-3">

                                        <h2 className="text-uppercase text-center role">Android App Developer</h2>

                                    </div>
                                    <div className="box">
                                        <div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item"><i className="fab fa-github"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-linkedin-in"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-instagram"></i></li>
                                                <li className="list-inline-item"><i className="fab fa-twitter"></i></li>
                                            </ul>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>





                    </div>
                </div>
            </div>




        </>
    );
}

export default Team;