import React from "react";
import ParticleBG from "./particleBG";
import Test from "./test";
import Test2 from "./test2";


function Contact() {



    return (
        <div className="contact">
            <ParticleBG />
            <h1>This is Contact page</h1>
            <Test />
            <Test2 />
        </div>

    );
}


export default Contact;