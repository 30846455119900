const productList = [
    {
        id: 1,
        name: "Brain Adorn",
        imgUrl: require("../images/brainAdornLogo.png"),
        appLink: "https://play.google.com/store/apps/details?id=com.pirhotech.brainadorn",
        isWeb: false
    },
    {
        id: 2,
        name: "PKCG Game",
        imgUrl: require("../images/pkcg.png"),
        appLink: require("../images/pkcgImage.png"),
        isWeb: false
    },
    {
        id: 3,
        name: "Cover Page",
        imgUrl: require("../images/coverpageImage.png"),
        appLink: require("../images/coverpageImage.png"),
        isWeb: false
    },
    {
        id: 4,
        name: "PiRhoTech",
        imgUrl: require("../images/whitelogo1.png"),
        appLink: "https://pirhotech.com/",
        isWeb: true 
    },
    {
        id: 5,
        name: "Valluk.com",
        imgUrl: require("../images/valluk.png"),
        appLink: "https://valluk.com/",
        isWeb: true 
    },
    {
        id: 6,
        name: "BdInclusive",
        imgUrl: require("../images/bdinclusive.png"),
        appLink: "https://bdinclusive.com/",
        isWeb: true 
    },
    {
        id: 7,
        name: "Dreamssel",
        imgUrl: require("../images/dreamssel.png"),
        appLink: "https://dreamssel.com/",
        isWeb: true 
    },
    {
        id: 8,
        name: "Truth Dare",
        imgUrl: require("../images/coming soon.jpg"),
        appLink: "",
        isWeb: false
    },
    {
        id: 9,
        name: "Recall",
        imgUrl: require("../images/coming soon.jpg"),
        appLink: "",
        isWeb: false
    },
    {
        id: 10,
        name: "Kormi Koi",
        imgUrl: require("../images/coming soon.jpg"),
        appLink: "",
        isWeb: false
    },
    {
        id: 11,
        name: "TT",
        imgUrl: require("../images/coming soon.jpg"),
        appLink: "",
        isWeb: false
    },
    {
        id: 12,
        name: "Quiz Muiz",
        imgUrl: require("../images/coming soon.jpg"),
        appLink: "",
        isWeb: false
    }
];

export default productList;
